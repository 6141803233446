// eslint-disable-next-line no-redeclare,no-unused-vars
function copy(id, param) {
  var object = param.object;
  var objectSub = param.objectSub;
  var objectSubAdd = param.objectSub;
  if (param.objectSubAdd) objectSubAdd = param.objectSubAdd;
  var refNew = {};
  var keys = getParamSettings(param, 'copyKeys');

  // create
  var create = function (row) {
    row.refObj = param.refObj;
    row.copy = 1;
    ajax({
      post: true,
      object: object,
      objectSub: objectSub,
      send: row,
      callback: function (xhr) {
        if (xhr.warning) return;
        if (param.callback) param.callback(xhr);
        row.id = xhr.id;
        if (!isPlainObject(refNew))
          ajax({
            post: true,
            object: object,
            objectSub: objectSub,
            id: row.id,
            send: refNew,
          });
      },
    });
  };

  // data
  ajax({
    object: object,
    objectSub: objectSubAdd,
    id: id,
    background: true,
    callback: function () {
      if (param.refField) {
        var row = param.rowAdd;
        row[param.refField] = id;
        create(row);
        return;
      }

      row = $.extend(true, {}, data[object][id]);
      if (param.rowAdd) row = $.extend(true, row, param.rowAdd);
      $.each(row, function (key, value) {
        if (keys) {
          if (!inArray(key, keys)) delete row[key];
        } else if (
          parameter[object].copySkip &&
          inArray(key, parameter[object].copySkip)
        )
          delete row[key];
        else if (
          strpos(key, '_') !== false ||
          getObjectValue(fields, [object, key, 'unique']) ||
          inArray(key, [
            'id',
            'createStamp',
            'editStamp',
            'disabled',
            'warning',
            'history',
            'short',
            'mailer',
            'mailing',
            'visited',
            'search',
          ])
        )
          delete row[key];
        else if (value && getObjectValue(fields, [object, key, 'reference'])) {
          var ref = fields[object][key].reference;
          if (fields[object][key].refTable) delete row[key];
          else if (
            parameter[object].copyRef &&
            inArray(ref, parameter[object].copyRef)
          ) {
            $.each(row[key], function (i, id) {
              let amount;
              if (fields[object][key].amount) [amount, id] = id.split('x');
              if (getObjectValue(data, [ref, id])) {
                if (!refNew[key]) refNew[key] = [];
                var pm = {object: ref, sub: true, ref: object};
                pm.callback = function (xhr) {
                  id = xhr.id;
                  if (fields[object][key].amount) id = amount + 'x' + id;
                  refNew[key].push(id);
                };
                copy(id, pm);
              }
            });
          } else if (param.sub && param.ref == ref) delete row[key];
          else if (
            checkSettings('pdf', fields[object][key]) ||
            fields[object][key].copySkip
          )
            delete row[key];
        } else if (
          inArray(key, ['name', 'title']) &&
          !param.sub &&
          !param.nameOrig
        )
          row[key] = 'Kopie ' + row[key];
        else if (!value) delete row[key];
      });

      if (param.sub) create(row);
      else
        setTimeout(function () {
          create(row);
        }, 500);
    },
  });
}
